/*!
 * swiped-events.js - v@version@
 * Pure JavaScript swipe events
 * https://github.com/john-doherty/swiped-events
 * @inspiration https://stackoverflow.com/questions/16348031/disable-scrolling-when-touch-moving-certain-element
 * @author John Doherty <www.johndoherty.info>
 * @license MIT
 */

// scrollElement has been added as an optional parameter in order to avoid scrolling to fire a swipe
const swipeEvents = (window: Window & typeof globalThis, document: Document, stopEl?: HTMLElement) => {
    if (window.nwcSwipeEvents) {
        return
    }
    window.nwcSwipeEvents = true

    // patch CustomEvent to allow constructor creation (IE/Chrome)
    // if (typeof window.CustomEvent !== 'function') {
    //     window.CustomEvent = function(event, params) {
    //         params = params || {bubbles: false, cancelable: false, detail: undefined}
    //
    //         let evt = document.createEvent('CustomEvent')
    //         evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    //         return evt
    //     }
    //
    //     window.CustomEvent.prototype = window.Event.prototype
    // }

    document.addEventListener('touchstart', handleTouchStart, false)
    document.addEventListener('touchmove', handleTouchMove, false)
    document.addEventListener('touchend', handleTouchEnd, false)

    let xDown: null | number = null
    let yDown: null | number = null
    let xDiff: null | number = null
    let yDiff: null | number = null
    let timeDown: null | number = null
    let startEl: HTMLElement | null = null

    // Variables added for checkScrollConditions
    let scrollTopArray: number[] = []
    let scrollLeftArray: number[] = []

    /**
     * Avoid swipe up or down events to fire by checking the scrollTop position of the optional param scrollEl (scrollable zone)
     * @param {string} direction - 'up' ,'down', 'left', 'right'
     * @returns {boolean}
     */
    function checkScrollCondition(direction: "up" |"down" | "left" | "right", e: TouchEvent) {
        if (stopEl === undefined) {
            return true
        }

        let el = e.target as HTMLElement
        let result = true
        let i = 0
        while (el !== stopEl && result) {
            if (isScrollable(el)) {
                switch (direction) {
                    case 'up':
                        result = scrollTopArray[i] === el.scrollHeight - el.clientHeight
                        break
                    case 'down':
                        result = scrollTopArray[i] === 0
                        break
                    case 'left':
                        result = scrollLeftArray[i] === el.scrollWidth - el.clientWidth
                        break
                    case 'right':
                        result = scrollLeftArray[i] === 0
                        break
                    default:
                        result = true
                }
            }
            el = el.parentNode as HTMLElement
            i++
        }
        return result
    }

    function memorizeStartElScrollPositions(el: HTMLElement) {
        if (stopEl === undefined || !stopEl.contains(el)) {
            return
        }
        scrollTopArray = []
        scrollLeftArray = []
        while (el !== stopEl) {
            scrollTopArray.push(el.scrollTop)
            scrollLeftArray.push(el.scrollLeft)
            el = el.parentNode as HTMLElement
        }
    }

    function isScrollable(el: HTMLElement) {
        return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth
    }

    /**
     * Fires swiped event if swipe detected on touchend
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleTouchEnd(e: TouchEvent) {
        // if the user released on a different target, cancel!
        if (startEl !== e.target) {
            return
        }

        // Calculate the data-swipe-threshold to be a percentage of the viewport
        const defaultVertical = window.innerHeight * 0.25 // 25% of the viewport
        const defaultHorizontal = window.innerWidth * 0.5 // 50% of the viewport

        let swipeThresholdVertical =
            getNearestAttribute(startEl!, 'data-swipe-threshold', defaultVertical)

        let swipeThresholdHorizontal =
            getNearestAttribute(startEl!, 'data-swipe-threshold', defaultHorizontal)

        let swipeTimeout = getNearestAttribute(startEl!, 'data-swipe-timeout', 500) // default 500ms
        let timeDiff = Date.now() - timeDown!
        let eventType = ''
        let changedTouches = e.changedTouches || e.touches || []

        if (Math.abs(xDiff!) > Math.abs(yDiff!)) {
            // most significant
            if (Math.abs(xDiff!) > swipeThresholdHorizontal && timeDiff < swipeTimeout) {
                if (xDiff! > 0) {
                    if (checkScrollCondition('left', e)) {
                        eventType = 'swiped-left'
                    }
                } else {
                    if (checkScrollCondition('right', e)) {
                        eventType = 'swiped-right'
                    }
                }
            }
        } else if (Math.abs(yDiff!) > swipeThresholdVertical && timeDiff < swipeTimeout) {
            if (yDiff! > 0) {
                if (checkScrollCondition('up', e)) {
                    eventType = 'swiped-up'
                }
            } else {
                if (checkScrollCondition('down', e)) {
                    eventType = 'swiped-down'
                }
            }
        }

        if (eventType !== '') {
            let eventData = {
                dir: eventType.replace(/swiped-/, ''),
                touchType: (changedTouches[0] as Touch & { touchType: string })?.touchType ?? 'direct', // This is a non standard feature used only by safari
                xStart: xDown!,
                xEnd: (changedTouches[0] || {}).clientX || -1,
                yStart: yDown!,
                yEnd: (changedTouches[0] || {}).clientY || -1
            }

            // fire `swiped` event event on the element that started the swipe
            startEl!.dispatchEvent(
                new CustomEvent('swiped', { bubbles: true, cancelable: true, detail: eventData })
            )

            // fire `swiped-dir` event on the element that started the swipe
            startEl!.dispatchEvent(
                new CustomEvent(eventType, { bubbles: true, cancelable: true, detail: eventData })
            )
        }

        // reset values
        xDown = null
        yDown = null
        timeDown = null
    }

    /**
     * Records current location on touchstart event
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleTouchStart(e: TouchEvent) {
        const target = e.target as HTMLElement
        // if the element has data-swipe-ignore="true" we stop listening for swipe events
        if (target.getAttribute('data-swipe-ignore') === 'true') {
            return
        }

        startEl = target
        memorizeStartElScrollPositions(startEl)

        timeDown = Date.now()
        xDown = e.touches[0].clientX
        yDown = e.touches[0].clientY
        xDiff = 0
        yDiff = 0
    }

    /**
     * Records location diff in px on touchmove event
     * @param {object} e - browser event object
     * @returns {void}
     */
    function handleTouchMove(e: TouchEvent) {
        if (!xDown || !yDown) {
            return
        }

        let xUp = e.touches[0].clientX
        let yUp = e.touches[0].clientY

        xDiff = xDown - xUp
        yDiff = yDown - yUp
    }

    /**
     * Gets attribute off HTML element or nearest parent
     * @param {object} el - HTML element to retrieve attribute from
     * @param {string} attributeName - name of the attribute
     * @param {any} defaultValue - default value to return if no match found
     * @returns {any} attribute value or defaultValue
     */
    function getNearestAttribute(el: HTMLElement, attributeName: string | number, defaultValue: number) {
        // walk up the dom tree looking for attributeName
        while (el && el !== document.documentElement) {
            let attributeValue = el.getAttribute(String(attributeName))

            if (attributeValue) {
                return parseInt(attributeValue, 10)
            }

            el = el.parentNode as HTMLElement
        }

        return defaultValue
    }
}

export default swipeEvents
