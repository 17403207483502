import './overlay.scss'
import { dispatchEvent } from '@kissui/helpers/src/assets/js/eventDispatch'
import { EVENT_POPIN_CLOSE, TIME_INSTANT, TIME_SLOW } from '@kissui/components'

const open = popin_id => {
    const overlay = document.createElement('DIV')
    overlay.classList.add('nb-overlay')
    overlay.addEventListener('click', () => {
        dispatchEvent({ eventName: EVENT_POPIN_CLOSE, args: { id: popin_id } })
    })
    document.body.appendChild(overlay)
    setTimeout(() => {
        overlay.classList.add('fadein')
    }, TIME_INSTANT)
}

const close = () => {
    if (!document.getElementsByClassName('nb-overlay').length) {
        return
    }
    const overlay = document.getElementsByClassName('nb-overlay')[0]
    overlay.classList.remove('fadein')
    setTimeout(() => {
        overlay.remove()
    }, TIME_SLOW)
}

export default { open, close }
