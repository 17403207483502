import swipeEvents from '@kissui/helpers/src/swipeEvents'
import createProps, { parseBool } from '@kissui/helpers/src/props.helpers'
import {
    recursiveSwitchAriaHidden,
    setFirstAndLastFocusable,
    focusin
} from '@kissui/helpers/src/assets/js/scopedKeyboardNav'
import {
    TIME_FAST,
    EVENT_POPIN_OPENED,
    EVENT_POPIN_CLOSED,
    EVENT_VIDEO_TOGGLE,
    EVENT_POPIN_OPEN,
    EVENT_POPIN_CLOSE,
    EVENT_POPIN_KEY_DOWN,
    EVENT_SWIPED_DOWN
} from '@kissui/components'
import Overlay from './components/overlay'
import viewportHelper from '@kissui/helpers/src/viewport.helpers'
import { dispatchEvent, readEvent } from '@kissui/helpers/src/assets/js/eventDispatch'

const STATE_OPEN = 'isOpen'
const SCROLL_LOCK = 'scroll-lock'

class Popin extends HTMLElement {
    constructor() {
        super()
        this.props = {}
        this.ariaModal = null
        this.isVideo = null

        this.boundOpen = this.open.bind(this)
        this.boundClose = this.close.bind(this)
        this.boundEscapeClose = this.escapeClose.bind(this)
        this.boundFocus = this.focus.bind(this)
        this.boundFocusin = this.focusin.bind(this)
    }

    connectedCallback() {
        this.style.display = 'none'
        this.props = createProps(this.attributes)

        if (!this.content && !this.slot) {
            this.slot = this.innerHTML
        }

        // Todo : investigate why we have this, because it's blocking popin stories
        if (!this.id && this.props.popin_id != null && this.props.popin_id != '') {
            this.id = this.props.popin_id
            // Todo : investigate why we have this, because it's blocking popin stories
            // return
        }

        this.ariaModal = false
        this.isVideo = false

        this.render()
    }

    attributeChangedCallback(name, oldValue, newValue) {
        this.props = createProps(this.attributes)

        if (name === 'content') {
            this.props.content = newValue
        }
    }

    static get observedAttributes() {
        return ['content']
    }

    disconnectedCallback() {
        if (!this.hasEvent) {
            return
        }
        this.unbindEvent()
    }

    render() {
        if (this.hasEvent) {
            this.unbindEvent()
        }
        const {
            heading = '',
            subheading = '',
            bgcolor = 'highlight',
            size = 'M',
            label_close = 'Close',
            image = '',
            image_alt = '',
            variation = 'before',
            content = '',
            heading_class = viewportHelper.is.mobile ? 't-sm-700-sl' : 'h-lg-700'
        } = this.props
        const slot = content ? content : this.slot

        if (slot === '') {
            this.bindEvent()
            this.classList.remove(STATE_OPEN)
            this.isOpen = false
            return false
        }
        this.checkIsVideo(slot)
        this.isVideo ? this.setAttribute('size', 'L') : this.setAttribute('size', size)

        // FYI : The role is dialog, not alertdialog (only used for alerts / warnings ...)
        this.setAttribute('role', 'dialog')
        // Set the popin aria-hidden by default
        this.setAttribute('aria-hidden', 'true')
        // Set bgcolor attribute
        this.setAttribute('bgcolor', bgcolor)
        // Set variation
        this.setAttribute('variation', variation)

        // Be careful to not remove the title div, even if empty, because it holds on purpose the top gradient for scroll effect
        this.innerHTML = `
            <nb-cta
                variation="navigation"
                ${bgcolor === 'white' ? `contrast="highlight"` : `contrast="light"`}
                icon_right="24/symbol/close"
                label="${label_close}"></nb-cta>
            <div class="wrapper">
                ${
                    heading &&
                    `<div class="title" >
                        ${heading && `<h2 class="${heading_class}">${heading}</h2>`}
                    </div>`
                }
                ${
                    subheading &&
                    `<div class="subtitle">
                            ${subheading && `<p class="t-sm-500">${subheading}</p>`}
                    </div>`
                }

                <div class="content t-sm-400" tabindex="-1">
                    ${
                        image &&
                        `<div class="popin-header-img"><img loading="lazy" src="${image}" alt="${image_alt}" title="${image_alt}" width="300" height="225" /></div>`
                    }
                    ${slot}
                </div>
                ${this.renderFooter()}
            </div>
            ${this.isVideo && viewportHelper.is.mobile ? '<div class="swipeOverlay"></div>' : ''}
        `

        // Expected css to be downloaded
        setTimeout(() => {
            this.style.display = 'inherit'
        }, 100)

        // Set the first and last focusable elements
        setFirstAndLastFocusable(this)

        this.buttonElement = this.querySelector('button')
        this.bindEvent()
    }

    renderFooter() {
        const { footer } = this.props
        const has_footer = parseBool(footer)

        if (!has_footer) {
            return ''
        }

        return `<footer></footer>`
    }

    checkIsVideo(slot) {
        const tempEl = document.createElement('div')
        tempEl.innerHTML = slot
        if (tempEl.children.length === 1 && tempEl.children[0].tagName === 'NB-VIDEO') {
            this.isVideo = true
            this.setAttribute('video', 'true')
        }
    }

    addLock() {
        document.getElementsByTagName('html')[0].classList.add(SCROLL_LOCK)
        Overlay.open(this.props.popin_id)
    }

    removeLock() {
        // In case we have popin inside a popin we shouldn't remove the scroll_lock
        const parentPopin = this.parentNode.closest('nb-popin')
        if (!parentPopin) {
            document.getElementsByTagName('html')[0].classList.remove(SCROLL_LOCK)
        }
        Overlay.close()
    }

    switchAriaModal() {
        this.ariaModal = !this.ariaModal
        // This is the future way to scope the SR focus to a modal, but it's not supported yet by all SR
        this.setAttribute('aria-modal', this.ariaModal)
        // So we need to set aria-hidden=false to all brothers and brothers of parent until body
        recursiveSwitchAriaHidden(this, this.ariaModal)
    }

    async open(e) {
        const event = readEvent(e)
        if (event.id !== this.id) {
            return
        }
        // Transitionend listener is fired multiple times (fore each css property)
        // so we need to instantiate it only when we open or close and then we remove it on focusin
        this.addEventListener('transitionend', this.boundFocus)
        document.addEventListener('focusin', this.boundFocusin)
        this.openingSource = document.activeElement

        this.addLock()
        this.switchAriaModal()
        this.setAttribute('aria-hidden', 'false')
        this.isOpen = !this.isOpen
        await new Promise(resolve => {
            setTimeout(() => {
                this.classList.add(STATE_OPEN)
                resolve()
            }, TIME_FAST)
        })
        // event variable already contains ID and other args sent along with dispatch event
        dispatchEvent({
            eventName: EVENT_POPIN_OPENED,
            args: event
        })
    }

    close(event) {
        event.cancelBubble = true
        if (event.stopPropagation) {
            event.stopPropagation()
        }
        // Transitionend listener is fired multiple times (fore each css property)
        // so we need to instantiate it only when we open or close, and then we remove it on focusin
        this.addEventListener('transitionend', this.boundFocus)
        document.removeEventListener('focusin', this.boundFocusin)
        this.removeLock()
        if (this.isVideo) {
            const openVideoPopin = this.closest('.has-open-video')
            if (openVideoPopin) {
                openVideoPopin.classList.remove('has-open-video')
            }
        }
        this.switchAriaModal()
        this.setAttribute('aria-hidden', 'true')
        this.isOpen = !this.isOpen
        this.classList.remove(STATE_OPEN)
        dispatchEvent({
            eventName: EVENT_POPIN_CLOSED,
            args: { id: this.props.popin_id }
        })
    }

    focus() {
        this.removeEventListener('transitionend', this.boundFocus)
        if (this.isOpen) {
            this.buttonElement.focus()
        } else {
            this.openingSource.focus()
        }
    }

    focusin(e) {
        focusin(e, this, true)
    }

    escapeClose(e) {
        if (e.key === 'Escape' && this.isOpen) {
            this.close(e)
        }
    }

    swipeDown(e) {
        if (this.isOpen) {
            this.close(e)
        }
    }

    swipeOverlayTogglePlayPause() {
        dispatchEvent({
            eventName: EVENT_VIDEO_TOGGLE,
            args: { id: this.props.popin_id }
        })
    }

    bindEvent() {
        this.hasEvent = true
        this.buttonElement != null && this.buttonElement.addEventListener('click', this.boundClose)
        window.addEventListener(EVENT_POPIN_OPEN, this.boundOpen)
        window.addEventListener(EVENT_POPIN_CLOSE, this.boundClose)
        document.addEventListener(EVENT_POPIN_KEY_DOWN, this.boundEscapeClose)

        if (viewportHelper.is.mobile) {
            swipeEvents(window, document, this)
            this.boundSwipeDown = this.swipeDown.bind(this)
            document.addEventListener(EVENT_SWIPED_DOWN, this.boundSwipeDown)
            if (this.isVideo) {
                this.swipeOverlay = this.querySelector('.swipeOverlay')
                this.boundSwipeOverlayTogglePlayPause = this.swipeOverlayTogglePlayPause.bind(this)
                this.swipeOverlay.addEventListener('click', this.boundSwipeOverlayTogglePlayPause)
            }
        }
    }

    unbindEvent() {
        this.hasEvent = false
        this.buttonElement != null &&
            this.buttonElement.removeEventListener('click', this.boundClose)
        window.removeEventListener(EVENT_POPIN_OPEN, this.boundOpen)
        window.removeEventListener(EVENT_POPIN_CLOSE, this.boundClose)
        document.removeEventListener(EVENT_POPIN_KEY_DOWN, this.boundEscapeClose)
        if (this.boundSwipeDown) {
            document.removeEventListener(EVENT_SWIPED_DOWN, this.boundSwipeDown)
            this.boundSwipeDown = null
        }
        if (this.boundSwipeOverlayTogglePlayPause) {
            this.swipeOverlay.removeEventListener('click', this.boundSwipeOverlayTogglePlayPause)
            this.boundSwipeOverlayTogglePlayPause = null
        }
    }
}

customElements.get('nb-popin') || customElements.define('nb-popin', Popin)

export default Popin
